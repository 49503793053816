import React, { useState } from "react";
import "./NewOurWork.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const NewOurWork = () => {


  const brands = [
    {
      src: "/meta-logo1.png",
      alt: "meta",
    },
    {
      src: "/gpm-logo1.png",
          alt: "gpm",
    },
    {
      src: "/bb-logo1.png",
           alt: "bb",
    },
    {
      src: "/vuezen-logo1.png",
      alt: "vuezen",
    },
    {
      src: "/sheluxe-logo1.png",
      alt: "sheluxe",
    },
    {
      src: "/mondus-logo1.png",
      alt: "mondus",
    },
    {
      src: "/floxytravel.png",
      alt: "floxy"
    }
  
  ];
  const settings = {
    dots: false, // Hide navigation dots
    arrows: false, // Disable arrows
    infinite: true, // Enable infinite scrolling
    speed: 500, // Animation speed
    slidesToShow: 5, // Number of logos visible at a time
    slidesToScroll: 1, // Scroll one logo at a time
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 3000, // Time between auto-scrolls
    responsive: [
      {
        breakpoint: 768, // For tablets
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // For mobile devices
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="NewOurWork" className="container">
      <div className="top_head_wrap ">
        <div>
          <div className="top_head_wrap portfolio_top_head">
            <div className="container">
              <div className="top_content_wrap">
                <p className="sm-title">Case Studies</p>
                <h1 className="heading1 text-center">
                  <div className="txt_gradient_wrap">
                    <div className="txt_gradient_top">Optimize.</div>
                    <div className="txt_gradient_top">Innovate.</div>
                    <div className="txt_gradient_top">Disrupt.</div>
                  </div>
                </h1>
                <div className="app__subhead">
                  Success stories that demonstrate our expertise to deliver
                  technology-led business
                  <br />
                  breakthroughs across startups, global brands, and Fortune 500s
                </div>
              </div>
            </div>
          </div>

         {/* <Link to='/case-study/MetaSpace' className="metaSpaceLink"> */}
         <div className="cta_section kfc_cta" >
            <figure style={{margin:"0"}} className="logo">
                    <img
                      src="/meta-logo1.png"
                      alt="Adidas"
                    />
                  </figure>
            {/* <h1 style={{ color: "white" }}>Metaspace</h1> */}
            <div className="flex_wrapper cta_text_wrap">
              <div className="col-6 col-left">
                <p className="para">
                  Blockchain-powered metaverse with <br />
                  AAA games, virtual pilgrimages,
                  <br /> and shopping.
                </p>
              </div>
              <div className="col-6 col-right col-right1">
                <div className="column_grid list3">
                  <ul className="inline_list">
                    <li>
                      <strong>10K+</strong>
                      Downloads
                    </li>
                    <li>
                      <strong>30K+</strong> Community Members
                    </li>
                    <li>
                      <strong>1st</strong> Indian P2E with <br />
                      Story & Arena
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
         {/* </Link> */}
        </div>

        <ul className="projects_list_wrap">
          <li className="project_list_item">
            {/* <Link to="/case-study/GPM"> */}
            <div className="project_card">
              <div className="column column_banner">
                <a target="_blank">
                  <figure
                    className="card_banner view_img_anim"
                    aria-hidden="true"
                  >
                    <img src="/GPM.png" alt="" />
                  </figure>
                </a>
              </div>
              <div className="column column_content">
                <div>
                  <figure className="logo">
                    <img src="/gpm-logo1.png" alt="Adidas" />
                  </figure>
                  {/* <h1>Government Polytechnic Mumbai</h1> */}
                  <h2 className="heading2">
                    Autonomous institute in Maharashtra offering various
                    engineering diploma programs.
                  </h2>
                 
                </div>
               
              </div>
            </div>
            {/* </Link> */}
           
          </li>

          <li className="project_list_item job_get_project is-inview">
           {/* <Link to='/case-study/BusinessBay'> */}
           <div className="project_card card2">
              <div className="column column_content">
                <div>
                  <figure className="logo">
                    <img src="/bb-logo1.png" alt="Jobget" />
                  </figure>
                  {/* <h1>Business Bay</h1> */}
                  <h2 className="heading2">
                    Dynamic firm offering NFC cards, professional resumes,
                    marketplace solutions, and AI innovations for businesses
                    worldwide.
                  </h2>
                  <div className="col-right col-right1 business">
                    <ul className="inline_list">
                      <li>
                        <strong>500K+</strong> Active Users
                      </li>
                      <li className="business">Global Reach</li>
                      <li className="business">Sustainable Practices</li>
                    </ul>
                  </div>
                </div>
               
              </div>
              <div className="column column_banner">
                <a target="_blank">
                  <figure
                    className="card_banner view_img_anim"
                    aria-hidden="true"
                  >
                    <img src="/BUSINESSBAY.png" alt="" />
                  </figure>
                </a>
              </div>
            </div>
            {/* </Link> */}
          </li>

          <li className="project_list_item is-inview">
          {/* <Link to='/case-study/mondus-properties'> */}
          <div className="project_card">
              <div className="column column_banner">
                <a target="_blank">
                  <figure
                    className="card_banner view_img_anim"
                    aria-hidden="true"
                  >
                    <img src="/mondus.png" alt="" />
                  </figure>
                </a>
              </div>
              <div className="column column_content">
                <div>
                  <figure className="logo">
                    <img src="/mondus-logo1.png" alt="" />
                  </figure>
                  {/* <h1>Mondus Properties</h1> */}
                  <h2 className="heading2">
                    Dubai-based real estate agency offering off-plan, rental,
                    and sale properties.
                  </h2>
                  <div className="col-right col-right1 col-right2">
                    <ul className="inline_list">
                      <li>
                        <strong>500+</strong>Properties Sold
                      </li>
                      <li>
                        <strong>50+</strong>Eco-Friendly Projects
                      </li>
                      <li>
                        <strong>4.4</strong>Customer Rating
                      </li>
                      <li>
                        <strong>95%</strong>Client Satisfaction
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              
            </div>
            {/* </Link> */}
          </li>

          <li className="project_list_item job_get_project is-inview">
            {/* <Link to='/case-study/vuezen'> */}
            <div className="project_card  card2">
              <div className="column column_content">
                <div>
                  <figure className="logo">
                    <img src="/vuezen-logo1.png" alt="" />
                  </figure>
                  {/* <h1>Vuezen</h1> */}
                  <h2 className="heading2">
                    Signature eyewear brand blending individuality, sustainable
                    practices, eco-friendly materials, and ecommerce-driven
                    customer experiences.
                  </h2>
                  <div className="col-right col-right2">
                    <ul className="inline_list">
                      <li>
                        <strong>1K+</strong> Customers
                        <br />
                        Global Shipping
                      </li>
                      <li>
                        <strong>90%</strong>Customer Satisfaction
                      </li>
                    </ul>
                  </div>
                </div>
                
              </div>
              <div className="column column_banner">
                <a target="_blank">
                  <figure
                    className="card_banner view_img_anim"
                    aria-hidden="true"
                  >
                    <img src="/vuezen.png" alt="" />
                  </figure>
                </a>
              </div>
            </div>
            {/* </Link> */}
            
          </li>

          <li className="project_list_item">
            {/* <Link to='/case-study/sheluxe'> */}
            <div className="project_card">
              <div className="column column_banner">
                <a target="_blank">
                  <figure
                    className="card_banner view_img_anim"
                    aria-hidden="true"
                  >
                    <img src="/sheluxe.png" alt="" />
                  </figure>
                </a>
              </div>
              <div className="column column_content">
                <div>
                  <figure className="logo">
                    <img
                      src="/sheluxe-logo1.png"
                      alt=""
                    />
                  </figure>
                  {/* <h1>Sheluxe</h1> */}
                  <h2 className="heading2">
                  Online store offering elegant lingerie masterpieces,
                    blending sensuality, sophistication, and timeless allure
                    through ecommerce excellence
                
                  </h2>
                  <div className="col-right">
                    <ul className="inline_list">
                      <li>
                        <strong>2K+</strong> Customers Global Shipping
                      </li>
                      <li>
                        <strong>95%</strong> Customer Satisfaction
                      </li>
                    </ul>
                  </div>

                
                </div>
              </div>
            </div>
            {/* </Link> */}
           
          </li>
{/* 
          <li className="project_list_item job_get_project is-inview">
            <div className="project_card">
              <div className="column column_content">
                <div>
                  <figure className="logo">
                    <img src="/sheluxe-logo1.png" alt="" />
                  </figure>
                  <h1>Shereal</h1>
                  <h2 className="heading2">
                  India's first all-women professional network with metaverse
                  and NFT marketplace.
                  </h2>
                 
                </div>
                
              </div>
              <div className="column column_banner">
                <a target="_blank">
                  <figure
                    className="card_banner view_img_anim"
                    aria-hidden="true"
                  >
                    <img src="/sheluxe.png" alt="" />
                  </figure>
                </a>
              </div>
            </div>
          </li>

          <li className="project_list_item">
            <div className="project_card">
              <div className="column column_banner">
                <a target="_blank">
                  <figure
                    className="card_banner view_img_anim"
                    aria-hidden="true"
                  >
                    <img src="/Colexion.png" alt="" />
                  </figure>
                </a>
              </div>
              <div className="column column_content">
                <div>
                  <figure className="logo">
                    <img src="/sheluxe-logo.png" alt="" />
                  </figure>
                  <h1>Colexion</h1>
                  <h2 className="heading2">
                    Metaverse ecosystem offering virtual shopping, concerts,
                    games, and pilgrimages.{" "}
                  </h2>
                  <ul className="inline_list">
                    <li>
                      <strong>500K+</strong>  Active Users <br/>Global Reach <br/>Sustainable Practices
                    </li>
                  </ul>
                </div>
                <div className="common__btn hv_blue">
                  <a
                    className="btn_line btn-effect view_case_study"
                    target="_blank"
                  >
                    <span>View Case Study</span>
                    <svg width="13px" height="10px" viewBox="0 0 13 10">
                      <polyline points="8 1 12 5 8 9"></polyline>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </li> */}
        </ul>

        <div className="brand_success">
          <h2 className="heading2">
            Add your brand's success story to our <br />
            exhaustive global list of elite brands
          </h2>
          <Slider {...settings}>
            {brands.map((brand, index) => (
              <div key={index}>
                <li>
                  <img
                    className={brand.className}
                    src={brand.src}
                    alt={brand.alt}
                  />
                </li>
              </div>
            ))}
          </Slider>
          {/* <div className="common__btn hv_blue">
            <a
              href="#"
              className="btn_line btn-effect btn_blue btn--show-modal"
             
            >
              <span>Join the Club</span>
              <svg width="13px" height="10px" viewBox="0 0 13 10">
                <polyline points="8 1 12 5 8 9"></polyline>
              </svg>
            </a>
          </div> */}
        </div>

        
      </div>
    </div>
  );
};


export default NewOurWork;
