import { React, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./JobDescription.css";
import logo from "../../Component/Images/BrandLogo.png";
import { ClipLoader } from "react-spinners";
import { ApiHelper, ApiHelperID } from "../../ApiHelper";
import { environmentVariables } from "../../config/env.config";
const baseUrl = environmentVariables?.apiUrl;

export const JobDescription = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [data, setData] = useState();
  const [relatedData, setRelatedData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      var method = "get";
      var apiUrl = `${baseUrl}api/v1/user/get-jobs-by-id`;

      const response = await ApiHelperID(method, apiUrl, id);
      setData(response?.data?.data);

      const type = 4;
      var relatedApiUrl = `${baseUrl}api/v1/user/get-jobs?type=${type}`;
      const relatedResponse = await ApiHelper(relatedApiUrl, method);
      setRelatedData(relatedResponse?.data?.data);
    } catch (error) {
      setData([]);
      setRelatedData([]);

      console.log("Error occured while fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  if (loading) {
    return (
      <div className="loading-container">
        <ClipLoader color="#36d7b7" loading={loading} size={50} />
        <p>Loading...</p>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="error-container">
        <p>Sorry, no job details available at the moment.</p>
      </div>
    );
  }

  return (
    <div className="job-description">
      <div className="left">
        {/* <div className="image">
          <img src={logo} />
        </div> */}
       <div className="main-title"> <h1 className="job-title">{data?.designation} </h1>
        <p className="location" style={{marginLeft:"10px" }}>
           ( {data?.type == "full_time" ? "Full-time" : "Part-time"})
          </p></div>
         
        <div className="jobLocation">
          {data.experience && (
            <>
              <i style={{ marginLeft: "0" }} className="fa-solid fa-briefcase"></i>
              <span>{data?.experience} </span> |{" "}
            </>
          )}
          <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
          <span>Not Disclosed</span> |{" "}
          <i className="fa-solid fa-location-dot"></i>
          <span>{data?.location}</span>
        </div>
        <div className="job-type">
          <p>{data?.department}</p>
          {/* <p className="location">
            {data?.type == "full_time" ? "Full-time" : "Part-time"}
          </p> */}
        </div>
        <div className="job-sections">
          <div className="description">
           { data?.companyDescription &&(
             <section className="job-section">
             <div>
               <h2 className="title">Company Description</h2>
             </div>
             <div className="content">
               <p>
                 <div
                   dangerouslySetInnerHTML={{
                     __html: data?.companyDescription,
                   }}
                 />
               </p>
             </div>
           </section>
           )}
           {data?.keyResponsibilities &&(
             <section className="job-section">
             {/* <div>
               <h2 className="title">Job Description</h2>
             </div> */}
             <div className="content">
               {/* <div
                 dangerouslySetInnerHTML={{ __html: data?.jobDescription }}
               /> */}

               <p className="title">
                 <strong>Key Responsibilities:</strong>
               </p>

               <div
                 dangerouslySetInnerHTML={{
                   __html: data?.keyResponsibilities,
                 }}
               />
             </div>
           </section>
           )}
           {data?.qualification && (
             <section className="job-section">
             <div>
               <h2 className="title">Qualifications</h2>
             </div>
             <div className="content">
               <div
                 dangerouslySetInnerHTML={{ __html: data?.qualification }}
               />
             </div>
           </section>
           )}
            {data?.additionalInfo &&(
              <section className="job-section">
              <div>
                <h2 className="title">Additional Information</h2>
              </div>
              <div className="content">
                <div
                  dangerouslySetInnerHTML={{ __html: data?.additionalInfo }}
                />
              </div>
            </section>
            )}
          </div>
          <section className="job-apply">
            <div className="intrested">
              <a onClick={() => navigate(`/application?id=${data.id}`)}>
                I'm interested
              </a>
            </div>
            {/* <div className="privacy">Privacy Policy</div> */}
          </section>
        </div>
      </div>
      <div className="right">
        <div className="refer-btn">
          {" "}
          <button
            className="intrest"
            onClick={() => navigate(`/application?id=${data.id}`)}
          >
            I'm intrested
          </button>
          {/* <button className="refer">Refer a friend</button> */}
        </div>
        {/* <div className="share">
          <p>SHARE THIS JOB</p>
          <div className="social">
            <i class="fa-brands fa-linkedin"></i>
            <i class="fa-brands fa-facebook"></i>
            <i class="fa-brands fa-instagram"></i>
          </div>
        </div> */}
        <div className="other-job">
          <p>OTHER JOBS AT BASTIONEX</p>

          {relatedData
            ?.filter((item) => item?.id != id)
            .map((item) => (
              <div
                className="job"
                onClick={() => navigate(`/jobdescription/?id=${item?.id}`)}
              >
                <h6 className="position">{item?.profile_name}</h6>
                <p>{item?.location}</p>
              </div>
            ))}

         
          <a href="" onClick={()=>navigate('/all_jobs')}>Show all jobs <i style={{marginLeft:'5px'}} class="fa-solid fa-arrow-right"></i></a>
        </div>
      </div>
    </div>
  );
};
