// import { Button, Tab, Tabs, Toolbar } from '@mui/material';
import React, { useEffect, useRef, useState } from "react";
import BrandLogo from "./Images/brandlogo1.png";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import * as Router from "../Routes";
import { Button, Container } from "./styledComponents/styledComponents";
import About from "./About";
import Services from "./Services";
import './Navbar.css'
import { Industries } from "./Industries/Industries";

const Main = styled.div`
  padding-bottom: 0px;
  width: 100%;
  position: -webkit-sticky;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const MobileViewAppBar = styled.div`
display: none;
  background-color: #fff;
  width: 100%;
  // border-radius: 0px 50px 50px 0px;
  padding: 10px 20px;
  position: relative;
  /* display: flex; */
  align-items: center;
  margin-right: 80px;
  justify-content: space-between;
  ${(p) =>
    p.scrollAppear &&
    `
   background-color:#f1f7f5;
`};
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  @media(max-width: 900px) {
    display:flex ;
  }
`;
const Logo = styled.img`
  width: 100px;
`;
const SmallDeviceLinkView = styled.div`
  background-color: #fff;
  position: fixed;
  width: 300px;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 20;
  padding-top: 20px;
  padding-left: 20px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  @media (min-width: 900px) {
    display: none;
  }
`;

const Call = styled.span`
font-size: 17px;
line-height: 13px;
font-family: Instrument Sans;
`;

const Link = styled.div`
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.25;
  cursor: pointer;
  display: flex;
  font-family:Instrument Sans;
`;

const Navigation = styled.div`
  background-color: #fff;
  // border-radius: 0px 50px 50px 0px;
  width: 100%;
  justify-content: flex-start;
  margin-right: 80px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  padding-left:3rem;
  justify-content: space-between;
  ${(p) =>
    p.scrollAppear &&
    `
   background-color:#f1f7f5;
`};
  box-shadow: 0px 0px 13px 2px rgb(0 0 0 / 5%);
  /* 0px 1px 2px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); */
  @media (max-width:992px){
  padding-left:15px;
  }
  @media (max-width: 900px) {
    display: none !important;
  }
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  gap:20px;
  justify-content:center;
`;
const NavLink = styled.div`
  // margin-left: 25px;
  font-size: 16px;
  position: relative;
  display: flex;
  cursor: pointer;
  font-family:'Instrument Sans';
  font-weight:500;
 
  @media (max-width: 845px) {
    
    font-size: 14px;
  }
`;
const NavLink2 = styled.div`
  // margin-left: 25px;
  font-size: 16px;
  position: relative;
  /* display: flex; */
  cursor: pointer;

 

  @media (max-width: 900px) {
    // margin-left: 15px;
  }
  @media (max-width: 845px) {
    // margin-left: 10px;
    font-size: 14px;
  }
`;


const DropDown = styled.div``;
const DropDownSection = styled.div`
  position: absolute;
  top: 30px;
  left: -20px;
  width: 250px;
  background-color: #fff;
  box-shadow: 0px 0px 13px 2px rgb(0 0 0 / 5%);
  /* padding-top:20px; */
  border-radius: 4px;
  @media (max-width: 767px) {
    position: relative;
    left: 0px;
    background: none;
    box-shadow: none;
    top: 0px;
    width: 100%;
    cursor: pointer;
  }
`;
const IconWrapper = styled.span`
  margin-left: 5px;
`;

const List = styled.div`
  font-size: 14px;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 20px;

  :hover {
    box-shadow: 0px 0px 13px 2px rgb(0 0 0 / 5%);
    @media (max-width: 767px) {
      box-shadow: none;
    }
  }
`;
const Image = styled.img`
  cursor: pointer;
`;
const Navbar = ({ showDropDown, setShowDropDown }) => {

  const [active, setActive] = useState("null")
  const [showNavigation, setShowNavigation] = useState(false);
  const headerRef = useRef();
  const [scrollAppear, setScrollAppear] = useState(false);
  const navigation = useNavigate();

  
  const scrollActive = () => {
    if (window.scrollY > 60) {
      setScrollAppear(true);
    } else {
      setScrollAppear(false);
    }
  };

  useEffect(() => {
    // window.addEventListener("scroll", scrollActive);
    window.addEventListener("scroll", scrollActive);
    return () => {
      window.removeEventListener("scroll", scrollActive);
    };
  }, [headerRef]);
 
  return (
  
   <Main ref={headerRef}>
      <Navigation>
        <Image
          src={BrandLogo}
          style={{ paddingLeft: "0rem", maxWidth:"12rem" }}
          onClick={() => {
            navigation("/");
          }}
        />
        <NavWrapper>
          <NavLink className="nav" onClick={() => navigation("/")}>Home</NavLink>
          <div className="aboutt">
          <span>
          <span className="nav" onClick={() => navigation("/aboutus")}>  About Us</span>
          </span>
          <About/>
        </div>
          {/* <NavLink onClick={() => (navigation('/whyUs'))}>Why Us</NavLink> */}







          
          <div className="nav-service">
            {/* <DropDown
              onClick={(e) => {
                e.stopPropagation();
                setShowDropDown(!showDropDown);
              }}
            > */}
             <span className=" nav"  onClick={()=>navigation("/services")}> Services</span>
              
            <Services/>
          </div>














          <div className="nav-industries" > <span><span className="nav">Industries </span></span>
            <Industries/>
          </div>
          <NavLink  className="nav" onClick={() => navigation("/our_work")}>Our Work</NavLink>
          <NavLink className="nav" onClick={() => navigation("/careers")}>Careers</NavLink>
          {/* <NavLink onClick={() => (navigation('/contact_us'))}>Contact Us</NavLink> */}

          <NavLink2>
            <Button
              bgcolor="#000"
              color="#fff"
              radius="30px"
              width="12rem"
              fontSize="18px"
              height="3.4rem"
              fontweight= "600"
              onClick={() => navigation("/contact_us")}
            >
            <Call> Contact Us <br /><Call style={{fontSize:"10px", fontWeight:"400"}}> for consultation Call </Call> </Call>
            </Button>
          </NavLink2>
        </NavWrapper>
      </Navigation>
      <MobileViewAppBar>
        <Logo
          src={BrandLogo}
          onClick={() => {
            navigation("/");
          }}
        />
        <MenuIcon
          onClick={() => {
            setShowNavigation(!showNavigation);
          }}
        />
      </MobileViewAppBar>
      {showNavigation && (
        <SmallDeviceLinkView>
          <CloseIcon
            onClick={() => {
              setShowNavigation(!showNavigation);
            }}
          />
          <Link
            onClick={() => {
              navigation("/");
              setShowNavigation(!showNavigation);
              setActive('Home')
            }}
          >
            Home
          </Link>
          <Link
            // onClick={() => {
            //   navigation("/aboutus");
            //   setShowNavigation(!showNavigation);
            //   setActive('About')
            // }}
            onClick={() => { active == "About" ? setActive('') : setActive('About') }}

          >
            About Us
          </Link>
          {active == 'About'?<About />: ''}
          <Link
            onClick={() => { active == "Services" ? setActive('') : setActive('Services') }}
          >
            Services
          
             
          </Link>
          {active == 'Services'?<Services />: ''}
         
          <Link
            onClick={() => {active == "Industries" ? setActive('') : setActive('Industries') }}
          >
            Industries
            </Link>
           {active == 'Industries'?<Industries />: ''}

            <Link
            onClick={() => {
              navigation("/our_work");
              setShowNavigation(!showNavigation);
              setActive('OurWork')
            }}
          >
            Our Work
          </Link>
          <Link
            onClick={() => {
              navigation("/careers");
              setShowNavigation(!showNavigation);
              setActive('Careers')
            }}
          >
            Careers
          </Link>
          <Link
            onClick={() => {
              navigation("/contact_us");
              setShowNavigation(!showNavigation);
              setActive('Contact')
            }}
          >
            Contact Us
          </Link>
        </SmallDeviceLinkView>
      )}

      {/* <About></About> */}
    </Main>
  );
};

export default Navbar;